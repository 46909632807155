<template>
  <LiefengContent>
    <template v-slot:title>机构用户</template>
    <template v-slot:toolsbarRight>
      <template>
        <Form class="search" :label-width="100" :inline="true">
            <FormItem label="用户名称">
              <Input v-model="search.name" placeholder="填输入用户名称"></Input>
            </FormItem>
              <Button type="primary" icon="ios-search-outline" style="margin-left: 10px; " @click="searchBtn">搜索</Button>
              <Button type="success" icon="ios-refresh" style="margin-left: 10px;" @click="reset">重置</Button>
          <Button type="info" icon="ios-add" style="margin-left: 10px;" @click="addOrg">加入机构</Button>
        </Form>
      </template>
    </template>
    <template v-slot:contentArea>
      <div class="container">
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :pageSize="pageSize"
            @hadlePageSize="hadlePageSize"
        ></LiefengTable>
      </div>
       <LiefengModal  :fullscreen="false" title="加入机构" @input="addModalFn" :value="addModal.status">
          <template #contentarea>
               <Form label-position="left" :label-width="100" >
                 <FormItem >
                    <span slot="label" class="validate">机构邀请码:</span>
                    <Input placeholder="限15字" v-model.trim="formData.orgInviteCode" :maxlength="15"></Input>
                  </FormItem>
               </Form>
          </template>
          <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="addModal.tip = true;addModalFn(false)">取消</Button>
            <Button type="primary" @click="addSave">确定</Button>
          </template>
    </LiefengModal>
    <LiefengModal :fullscreen="false" title="查看用户" @input="checkModalFn" :value="checkModal.status">
        <template #contentarea>
             <div class="equiment-title">基本信息</div>
             <div class="datail-equiment" style="padding-left:20px">
               <div class="img-div">
                 <div style="display:flex"><div style="height:100px;line-height:100px">头像:</div><div><img style="height:100px;border-radius:50%;margin-left:20px" v-if="detailMessage.avatarUrl" :src="detailMessage.avatarUrl"></div></div>
                 <div class="other"><span style="padding-right:10px">姓名:</span>{{detailMessage.userName || '暂无'}}</div>
                 <div class="other"><span style="padding-right:10px">性别:</span>{{detailMessage.sex == 0 ?'未知':detailMessage.sex == 1 ?'男' : detailMessage.sex == '2' ?'女':''}}</div>
                 <div class="other"><span style="padding-right:10px">手机号:</span>{{detailMessage.mobile || '暂无'}}</div>
                 <div class="other"><span style="padding-right:10px">审核状态:</span>{{detailMessage.status == 1 ?'待审核':detailMessage.status == 2 ?'审核通过':detailMessage.status == 3 ?'审核未通过':'暂无'}}</div>
               </div>
            </div>
        </template>
          <template #toolsbar>
            <Button type="primary" @click="checkModal.status = false">确定</Button>
          </template>
    </LiefengModal>
    <LiefengModal class="group" :fullscreen="false" title="审核用户" @input="statusModalFn" :value="statusModal.status">
        <template #contentarea>
             <Form label-position="left" :label-width="100" >
                 <FormItem >
                    <span slot="label" class="validate">审核状态:</span>
                    <Select style="text-align:left;" v-model="statusModal.name" placeholder="请选择组织维度"  @on-change="selectOption" >
                    <Option :value="item.id" v-for="(item,index) in options" :key="index">{{item.label}}</Option>
                  </Select>
                  </FormItem>
               </Form>
        </template>
          <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="statusModal.tip = true;statusModalFn(false)">取消</Button>
            <Button type="primary" @click="statusSave">确定</Button>
          </template>
    </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/institutionaluser')
import LiefengTable from "@/components/LiefengTable";
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";


export default {
  components: {
    LiefengTable,
    LiefengModal,
    LiefengContent
  },
  data() {
    return {
      //table列表
      tableColumns: [
        {
          title: '姓名',
          key: 'userName',
          minWidth: 100,
          align: "center",
        },
        {
          title: '头像',
          align: "center",
          width: 80,
          render:(h,params)=>{
            return h('div',[
                h('img',{
                  props:{

                  },
                  style:{
                    width:'30px',
                    height:'30px',
                    borderRadius:'50%',
                    textAlign:'center',
                    display:params.row.avatarUrl?'block' : 'none'
                  },
                  attrs:{
                    src:params.row.avatarUrl,
                  }
                },

                )
            ])
          }
        },
        
        {
          title: '性别',
          minWidth: 100,
          align: "center",
          render:(h,params) =>{
            return h ('div',{
            },params.row.sex == 0 ?'未知':params.row.sex == 1 ?'男':params.row.sex == 2 ?'女':'')
          }
        },
        {
          title: '手机号',
          key: 'mobile',
          align: "center",
          minWidth: 130
        },
        {
          title: '审核状态',
          key: 'status',
          align: "center",
          minWidth: 130,
          render:(h,params) =>{
            return h('div',{},params.row.status == 1 ?'待审核':params.row.status == 2 ? '审核通过' : params.row.status == 3 ?'审核未通过':'')
          }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            let data = params.row
            return h('div', {
              style:{
                display:'flex',
                margin:'0 auto',
                justifyContent:'center'
              }
            },[
          
              h('Button', {
                props: {
                  type: 'success',
                  size: 'small',
                },
                style: {
                  marginRight: '3px'
                },
                on: {
                  click: () => {
                    this.checkDeatil(params.row.custGlobalId)
                  }
                }
              }, '查看'),
              h('Button', {
                props: {
                  type: 'info',
                  size: 'small',
                },
                style: {
                  marginRight: '3px',
                  display:params.row.status !='1' ? 'none' :'block'
                },
                on: {
                  click: () => {
                    this.statusModal.name = ''
                    this.statusModal.status = true
                    this.statusModal.id = params.row.custGlobalId
                  }
                }
              }, '审核'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small',
                },
                style: {},
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: "温馨提示",
                      content: "<p>您正在删除当前数据，此操作不可逆，是否继续？</p>",
                      onOk: () => {
                       this.deleteFn(params.row.custGlobalId)
                      },
                    })
                    
                  }
                }
              }, '解绑/移除'),
            ]);
          }
        }
      ],
      tableData: [], //table的数据
      loading: false, //table 加载
      page: 1, //分页
      total: 0,   //总数量
      pageSize: 20, //每一页显示的数量
      currPage:0,
      addModal:{
         status:false,
         tip:false
      },
      // 加入机构邀请码
      formData:{
        orgInviteCode:''
      },
      checkModal:{
        status:false
      },
      // 存放用户详情
      detailMessage:{},
      // 审核用户模态框
      statusModal:{
        status:false,
        tips:false,
        name:'',
        id:''
      },
      options:[
        {label:"审核通过",id:'2'},
        {label:"审核未通过",id:'3'},
      ],
      search:{
        name:''
      }
    };
  },
  methods: {
    // 方法部分
    // 搜索
    searchBtn(){
      this.getList()
    },
    // 重置
    reset(){
      this.page = 1
      this.pageSize = 20
      this.search.name = ''
      this.getList()
    },
    // 选择框
    selectOption(val){
      this.statusModal.name = val
    },
    // 审核模态框确认按钮
    statusSave(){
      if(this.statusModal.name == ''){
        this.$Message.warning({
          content:'请选择审批状态',
          background:true
        })
        return
      }else{
        let data = {
          status:this.statusModal.name,
          userCustGlobalId:this.statusModal.id
        }
        this.updateExamine(data)
      }
    },
    // 关闭审核模态框
    statusModalFn(status){
      // if(!status && this.statusModal.tip){
      //       this.$Modal.confirm({
      //           title: "温馨提示",
      //           content: "您正在关闭此窗口，信息将不会保存，是否继续",
      //           onOk: () => {
      //               this.statusModal.status = status;
                    
      //           }
      //        });
      //   }else{
            this.statusModal.tip = false
             this.statusModal.status = status;
        // }
    },
    // 点击查看用户按钮
    checkModalFn(status){
      this.checkModal.status = status
    },
    // 点击新增按钮
    addOrg(){
      this.addModal.status = true
      this.formData.orgInviteCode = ''
    },
    // 加入机构模态框确定按钮
    addSave(){
      if(this.formData.orgInviteCode == ''){
        this.$Message.warning({
          content:'邀请码不能为空',
          background:true
        })
        return
      }else{
        this.joinOrg(this.formData.orgInviteCode)
      }
    },
    //选择接收人弹窗状态改变
    addModalFn(status) {
        if(!status && this.addModal.tip){
            this.$Modal.confirm({
                title: "温馨提示",
                content: "您正在关闭此窗口，信息将不会保存，是否继续",
                onOk: () => {
                    this.addModal.status = status;
                    this.addModal.tip = false
                }
             });
        }else{
             this.addModal.status = status;
        }
       
    },   
    hadlePageSize(val) {  
      this.page = val.page
      this.pageSize = val.pageSize
      this.getList()
    },

    // 接口部分
    // 获取分页详情
    getList(){
      this.loading = true
       this.$get("/orgzz/pc/staff/selectOrgUserPageByOrgId", {
        orgId: parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
        // orgId:11434,
        page:this.page,
        pageSize:this.pageSize,
        userName:this.search.name
      }).then(res => {
        this.loading = false;
        if (res.code == 200 && res.dataList) {
          this.tableData = res.dataList;
          this.currPage = res.currentPage;
          this.pageSize = res.pageSize;
          this.total = res.maxCount;
        } else {
          this.loading = false
          this.$Message.error({
            background: true,
            content: res.msg
          });
        }
      })
    },
    // 加入机构接口
    joinOrg(data){
      this.$post('/orgzz/pc/staff/joinOrganization',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        // custGlobalId:'C210619144847f8cdc1a4913872',
        orgCode:data
      }).then(res=>{
        if(res.code == 200){
          this.$Message.success({
            background:true,
            content:'成功加入机构'
          })
          this.addModal.status = false
          this.addModal.tip = false
          this.getList()
        }else{
          if(res.code == null){
            this.$Message.error({
              background:true,
              content:'加入失败'
            })
            return
          }else{
             this.$Message.error({
              background:true,
              content:res.msg
            })
            return
          }
         
        }
      })
    },
    // 查看信息
    checkDeatil(data){
      this.$Message.loading({
         content: "正在加载信息，请稍等...",
         duration: 0,
         background:true
      });
      this.$get('/orgzz/pc/staff/selectUserByGlobalId',{
        custGlobalId:data,
        orgId	:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId
        // orgId:11434,
      }).then(res=>{
        if(res.code == 200){
          this.checkModal.status = true
          this.detailMessage = res.data
          this.$Message.destroy();
        }else{
          this.$Message.destroy();
          this.$Message.error({
            background:true,
            content:'获取详情失败'
          })
          return
        }
      })
    },
    // 解绑、移除接口
    deleteFn(data) {
      this.$post('/orgzz/pc/staff/deleteOrgUser',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        // custGlobalId:'C210619144847f8cdc1a4913872',
        orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
        // orgId:11434,
        userCustGlobalId:data,
      }).then(res=>{
        if(res.code == 200){
          this.$Message.success({
            background:true,
            content:'移除/解绑成功'
          })
          this.page = this.currPage
          this.getList()
          return     
        }else{
          if(res.code == null || res.desc != 'success'){
            this.$Message.error({
            background:true,
            content:'删除失败'
           })
           return
          }else{
            this.$Message.error({
            background:true,
            content:res.msg
           })
           return
          }
          
        }
      })
    },
    // 审批接口
    updateExamine(data){
      this.$post('/orgzz/pc/staff/examineOrgUser',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        // custGlobalId:'C210619144847f8cdc1a4913872',
        orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
        // orgId:11434,
        status:data.status,
        userCustGlobalId:data.userCustGlobalId
      },{"Content-Type": "application/json"}).then(res=>{
        if(res.code == 200){
          this.$Message.success({
            content:'操作成功',
            background:true
          })
          this.statusModal.status = false
          this.statusModal.tip = false
          this.page = this.currPage
          this.getList()
          return
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
        }
      })
    }
  },
  created(){
    this.getList()
  }
};
</script>

<style scoped lang='less'>
.equiment-title{
    position: relative;
    padding-left: 20px;
    margin:10px 0;
    &::after{
        position: absolute;
        content:'';
        top: 0;
        left: 0;
        width: 5px;
        height: 20px;
        background-color: #2db7f5;
    }
}
.other{
  padding: 10px 0 ;
}
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}
.group{
  /deep/ #modal_contentarea{
        overflow: visible !important;
    }
}
</style>


